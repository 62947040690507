import React from "react"
import styled from "styled-components"
import { IconType } from "react-icons"

import { GridItem } from "./layout/Grid"
import H3Header from "./H3Header"

const ServiceItemContainer = styled(GridItem)`
  background-color: #fff;
  color: #2b2e34;
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.16);

  padding-top: 45px;
  padding-bottom: 45px;

  & > *:last-child {
    margin-bottom: 0;
  }
`

const IconContainer = styled.div`
  font-size: 50px;
  color: var(--progwise-green);
  text-align: center;
`

interface ServiceItemProps {
  icon: IconType
  title: string
}

const ServiceItem: React.FC<ServiceItemProps> = ({
  title,
  icon: Icon,
  children,
}) => {
  return (
    <ServiceItemContainer>
      <IconContainer>
        <Icon />
      </IconContainer>
      <H3Header>{title}</H3Header>
      {children}
    </ServiceItemContainer>
  )
}

export default ServiceItem
