import React from "react"
import styled from "styled-components"

import { GridItem, GridItemMiddleToRight } from "./layout/Grid"
import H3Header from "./H3Header"
import ProfileImage from "./ProfilImage"
import { Link } from "gatsby"

const Center = styled.div`
  display: flex;
`

const Position = styled.p`
  text-transform: uppercase;
  color: var(--progwise-blue);
  margin: 2px 0;
`

const CustomH3Header = styled(H3Header)`
  text-align: left;
  margin: 2px 0;

  > a {
    text-decoration: none;
  }
`

const Paragraph = styled.p`
  margin: 2px 0;
`

const BlueLink = styled(Link)`
  color: var(--progwise-blue);
`

interface TeamGridItemProps {
  about: string
  firstName: string
  fullName: string
  id: string
  image: string
  position: string
}

const TeamGridItem: React.FC<TeamGridItemProps> = ({
  about,
  firstName,
  fullName,
  id,
  image,
  position,
}) => {
  const personHref = `/team/${id}`

  return (
    <>
      <GridItem>
        <Link to={personHref}>
          <Center>
            <ProfileImage source={image} fullName={fullName} />
          </Center>
        </Link>
      </GridItem>
      <GridItemMiddleToRight>
        <CustomH3Header>
          <Link to={personHref}>{fullName}</Link>
        </CustomH3Header>
        <Position>{position}</Position>
        <Paragraph>{about}</Paragraph>
        <Paragraph>
          <BlueLink to={personHref}>more about {firstName}</BlueLink>
        </Paragraph>
      </GridItemMiddleToRight>
    </>
  )
}

export default TeamGridItem
