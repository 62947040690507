import React from "react"
import styled from "styled-components"

import { GridItem } from "./layout/Grid"
import H3Header from "./H3Header"

const CenteredGridItem = styled(GridItem)`
  text-align: center;
`

const TechImage = styled.img`
  width: 56px;
  height: 56px;
`

interface StackGridItemProps {
  icon: string
  title: string
  stackItems: string[]
}

const StackGridItem: React.FC<StackGridItemProps> = ({
  icon,
  title,
  stackItems,
}) => (
  <CenteredGridItem>
    <TechImage src={icon} alt={title} />
    <H3Header>{title}</H3Header>
    <p>{stackItems.join(" - ")}</p>
  </CenteredGridItem>
)

export default StackGridItem
