import React from "react"

interface DateFormatProps {
  value: string
}

const DateFormat: React.FC<DateFormatProps> = ({ value }) => {
  const date = new Date(value)
  const formattedValue = Intl.DateTimeFormat("en", {
    day: "numeric",
    month: "long",
    year: "numeric",
  }).format(date)

  return <time dateTime={value}>{formattedValue}</time>
}

export default DateFormat
