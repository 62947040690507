import React from "react"
import styled from "styled-components"
import {
  FaCalendar,
  FaUser,
  FaFlagCheckered,
  FaRegCheckCircle,
  FaMeetup,
  FaMapMarker,
} from "react-icons/fa"
import { useStaticQuery, graphql, Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { OutboundLink } from "gatsby-plugin-google-analytics"

import Layout from "../components/layout/Layout"
import {
  GridContainer,
  GridItemFullWidth,
  GridItemTwoColumns,
  GridItem,
} from "../components/layout/Grid"
import Seo from "../components/Seo"
import H2Header from "../components/H2Header"
import ServiceGridItem from "../components/ServiceGridItem"
import TeamGridItem from "../components/TeamGridItem"
import StackGridItem from "../components/StackGridItem"
import H3Header from "../components/H3Header"
import SectionWithAnchor from "../components/SectionWithAnchor"

import terraformSvg from "../images/technologies/terraform.svg"
import kubernetesSvg from "../images/technologies/kubernetes.svg"
import databaseSvg from "../images/technologies/database.svg"
import iotSvg from "../images/technologies/iot.svg"
import languagesSvg from "../images/technologies/languages.svg"
import angularSvg from "../images/technologies/angular.svg"
import springBootSvg from "../images/technologies/spring-boot.svg"
import DateFormat from "../components/helper/DateFormat"

const GridContainerGreen = styled(GridContainer)`
  background-color: var(--progwise-green);
  color: #fff;
`

const GridContainerGray = styled(GridContainer)`
  background-color: #727272;
  color: #fff;

  & a {
    color: #fff;
    font-weight: bold;
    text-decoration: none;
  }
`

const GridContainerBlueBox = styled(GridContainer)`
  background-color: rgba(var(--progwise-blue-rgb), 0.86);
  color: white;
  background-clip: content-box;

  & a {
    color: #fff;
    text-decoration: none;
  }
`

const GridContainerCentered = styled(GridContainer)`
  align-items: center;
`

const CenteredBigParagraph = styled.p`
  text-align: center;
  font-size: 22px;
`

const CircleGridItem = styled(GridItem)`
  border: solid 6px var(--progwise-blue);
  border-radius: 50%;
  height: 0;
  padding-bottom: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  & ul {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: 0 auto;
    padding: 0;
    list-style: none;
    line-height: 3em;
    font-size: 18px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
`

const IndexPage = () => {
  const {
    allTeamJson: { nodes: team },
    allMeetupEvent: { nodes: meetupEvents },
    meetupImage,
  } = useStaticQuery(graphql`
    {
      allTeamJson {
        nodes {
          id
          fullName
          firstName
          position
          about
          image
        }
      }
      allMeetupEvent(limit: 3, sort: { fields: local_date, order: DESC }) {
        nodes {
          id
          name
          local_date
          link
          venue {
            name
          }
          fields {
            excerpt
          }
        }
      }
      meetupImage: file(relativePath: { eq: "meetup_blue.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <Seo title="Home" />
      <SectionWithAnchor id="mission">
        <GridContainer>
          <GridItemFullWidth>
            <H2Header>Mission</H2Header>
            <CenteredBigParagraph>
              Progwise.net is a powerful network of talented software
              developers.
            </CenteredBigParagraph>
            <CenteredBigParagraph>
              Our main goal is to know the best people and the most exciting
              projects to establish the most productive relationship between
              customers and developers.
            </CenteredBigParagraph>
          </GridItemFullWidth>
        </GridContainer>
      </SectionWithAnchor>
      <SectionWithAnchor id="meet-us">
        <BackgroundImage
          Tag="section"
          fluid={meetupImage.childImageSharp.fluid}
        >
          <GridContainerBlueBox>
            <GridItemFullWidth />
            <GridItemFullWidth>
              <H2Header>
                <FaMeetup /> Meet Us
              </H2Header>
            </GridItemFullWidth>
            {meetupEvents.map(event => (
              <GridItem key={event.id}>
                <OutboundLink href={event.link}>
                  <p
                    css={`
                      margin-bottom: -20px;
                    `}
                  >
                    <DateFormat value={event.local_date} />
                  </p>
                  <H3Header textAlign="left">{event.name}</H3Header>
                  <p>{event.fields.excerpt}</p>
                  <p>
                    <strong>
                      {event.venue.name} <FaMapMarker />
                    </strong>
                  </p>
                </OutboundLink>
              </GridItem>
            ))}
            <GridItemFullWidth />
          </GridContainerBlueBox>
        </BackgroundImage>
      </SectionWithAnchor>
      <SectionWithAnchor id="services">
        <GridContainerGreen>
          <GridItemFullWidth>
            <H2Header>The Main Services</H2Header>
          </GridItemFullWidth>
          <ServiceGridItem icon={FaCalendar} title="Workshops">
            <p>Prepare our team for the new technologies and methods.</p>
            <p>
              Already prepared are Kubernetes, Terraform, Agility for the
              management, Angular and more.
            </p>
          </ServiceGridItem>
          <ServiceGridItem icon={FaUser} title="Coaching">
            <p>
              What is the best practice for terraforming some services, for
              setting up the K8s deployments, for unit testing and much more.
            </p>
            <p>We love to learn and to teach every day.</p>
          </ServiceGridItem>
          <ServiceGridItem icon={FaFlagCheckered} title="Getting stuff done">
            <p>
              …is our main focus. Done means done in production, no tech debts
              and leaving more space for future improvements instead of
              deteriorating the project.
            </p>
          </ServiceGridItem>
        </GridContainerGreen>
      </SectionWithAnchor>
      <SectionWithAnchor id="team">
        <GridContainerCentered>
          <GridItemFullWidth>
            <H2Header>The Core Team</H2Header>
          </GridItemFullWidth>
          {team.map(person => (
            <TeamGridItem
              key={person.id}
              about={person.about}
              firstName={person.firstName}
              fullName={person.fullName}
              id={person.id}
              image={person.image}
              position={person.position}
            />
          ))}
        </GridContainerCentered>
      </SectionWithAnchor>
      <SectionWithAnchor id="devops-factory">
        <GridContainerGray>
          <GridItemFullWidth>
            <H2Header>The DevOps Factory</H2Header>
          </GridItemFullWidth>
          <GridItemTwoColumns>
            <H3Header textAlign="left">
              Roadmap to get a highly efficient software factory
            </H3Header>
            <p>
              Managing software projects is much like managing production lines
              in factories. Reading books like{" "}
              <a href="https://itrevolution.com/book/the-phoenix-project">
                „The Phoenix Project“
              </a>{" "}
              make skies clear again.
            </p>
            <p>
              To get any evolution in managing software development you need to
              make stuff visible first. All team members are working together on
              a board on which the current status of the factory is visible. In
              factories, you can just look out the ample hall to see
              intermediate artefacts moving from crude materials into the
              products you sell. In software development we must be clear what
              the final product is: „Software running in production“ and not
              something like „Software developed“.
            </p>
            <p>
              We want to guide our customers towards highly efficient software
              factories instead of having too many people doing unproductive
              work. Teams love to perform and want to show how much they got
              done!
            </p>
          </GridItemTwoColumns>
          <CircleGridItem>
            <ul>
              <li>
                <FaRegCheckCircle />
                &nbsp; &nbsp; Plan
              </li>
              <li>
                <FaRegCheckCircle />
                &nbsp; &nbsp; Build
              </li>
              <li>
                <FaRegCheckCircle />
                &nbsp; &nbsp; Run
              </li>
            </ul>
          </CircleGridItem>
        </GridContainerGray>
      </SectionWithAnchor>
      <SectionWithAnchor id="stack">
        <GridContainer>
          <GridItemFullWidth>
            <H2Header>Our Stack</H2Header>
          </GridItemFullWidth>
          <StackGridItem
            title="Languages"
            stackItems={[
              ".NET",
              "C#",
              "F#",
              "VB",
              "Scala",
              "Java",
              "Go",
              "JavaScript",
              "TypeScript",
              "Python",
            ]}
            icon={languagesSvg}
          />
          <StackGridItem
            title="Backend"
            stackItems={["Node.js", "Spring Boot", "dotnetcore"]}
            icon={springBootSvg}
          />
          <StackGridItem
            title="Frontend"
            stackItems={["HTML", "CSS", "React", "Angular", "Vue"]}
            icon={angularSvg}
          />
          <StackGridItem
            title="Database"
            stackItems={[
              "Postgres",
              "Azure Cosmos",
              "MS SQL",
              "MySQL",
              "Linux Time-Series",
            ]}
            icon={databaseSvg}
          />
          <StackGridItem
            title="Docker & Kubernetes"
            stackItems={[
              "Containerize",
              "Versioning",
              "Scaling",
              "Organising Containers",
            ]}
            icon={kubernetesSvg}
          />
          <StackGridItem
            title="Terraform"
            stackItems={[
              "Infrastructure as Code",
              "MS Azure",
              "Google Cloud",
              "Amazon Web Service",
              "Salesforce",
              "Heroku",
              "On Premise",
            ]}
            icon={terraformSvg}
          />
          <StackGridItem
            title="IoT & Eventing"
            stackItems={["MQTT", "VerneMQ", "RabbitMQ", "Apache Kafka"]}
            icon={iotSvg}
          />
          <GridItemFullWidth>
            <CenteredBigParagraph>
              If you want to see what else we do, visit our{" "}
              <Link to="/blog">blog</Link>.
            </CenteredBigParagraph>
          </GridItemFullWidth>
        </GridContainer>
      </SectionWithAnchor>
    </Layout>
  )
}

export default IndexPage
